@font-face {
  font-family: "Boba Cups";
  src: url("./fonts/Boba%20Cups.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bubblegum";
  src: url("./fonts/Bubblegum.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

button {
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  text-align: inherit;
}

a{
  color: white;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Bubblegum";
  scroll-behavior: smooth;
  background-color: #ada68b;
}

header {
  background-color: #333;
  color: #fff;
  padding-bottom: 0.5rem;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
}

h1{
  font-family: "Boba Cups";
}

h3{
  font-family: "Boba Cups";
}

nav li {
  margin-right: 1rem;
}

main {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items:center;
  flex-grow: 1;
}

section {
  position:relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

footer {
  background-color: #333;
  box-shadow: inset 0px 5px 5px -5px rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  border-radius: 100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.container {
  position: relative;
  background: #2c3e50;
  height: 50vh;
}

.list-container{
  display: flex;
  flex-direction: column;
}

.custom-shape-divider-top-1681932086 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1681932086 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 40px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1681932086 .shape-fill {
  fill: #ada68b;
}

.list{
  display: flex;
  flex-direction: column;
}

.expandable-card{
  background-color: #8a7d63;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
  border-radius: 10px;
  color: white;
  font-size: 32px;
}

.expandable-card.expanded{
  background: linear-gradient(to bottom, #8a7d63, #695d46);
  padding-bottom: 40px;
}

.expandable-card .content {
  display: none;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.address-card{
  background-color: #8a7d63;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 14px;
  color: yellow;
  padding: 10px; 
}

.expandable-card.expanded .content {
  margin-top: 15px;
  font-size: 20px;
  width: 350px;
  color: yellow;
  display: block;
  opacity: 1;
}

.expandable-card:hover {
  cursor: pointer;
}

.custom-shape-divider-top-1681932574 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1681932574 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 40px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1681932574 .shape-fill {
  fill: #9c956e;
}

.aboutContainer{
  display: flex;
  width: 1280px;
}

.frameContainer{
  width: 500px;
}


.cardContainer{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: #8a7d63;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 200px;
  border-radius: 10px;
}

.buttonContainer{
  display:flex;
  justify-content: center;
  align-items:center;
  height: 100%;
  width: 15%;
}

.image-container{
  margin-right: 35px;
}


#how-to-buy{
  background-color: #9c956e;
}

#about{
  background-color: #9c956e;
  display: flex;
  justify-content: center;
  align-items: center;
}


#tokenomics{
  display: flex;
}

#intro{
  display: flex;
}

.sectionContainer{
  width: 50%;
}

.paragraph{
  font-size: 20px;
}

.intro{
  display:flex;
  width: 500px;
}

.text{
  margin-left: 20px;
  display:flex;
  flex-direction: column;
  width: 400px;
}
.about-text{
  margin-left: 140px;
  display:flex;
  flex-direction: column;
  width: 400px;
}
.headline{
  display: flex;
  justify-content: center;
  align-items:center;
}

.gridContainer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr); 
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.grid1 { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 1 / 2 / 2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #8a7d63;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}
.grid2 { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 2 / 2 / 3;
  background-color: #8a7d63;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
 }
.grid3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: 2 / 1 / 3 / 2;
  background-color: #8a7d63;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: "black";
  border-radius: 10px; }
.grid4 { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: 2 / 2 / 3 / 3;
  background-color: #8a7d63;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px; }

.grid1:hover{
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-4px);
    transition: all 0.3s ease-in-out;
}
.grid2:hover{
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
  transition: all 0.3s ease-in-out;
}
.grid3:hover{
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
  transition: all 0.3s ease-in-out;
}
.grid4:hover{
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
  transition: all 0.3s ease-in-out;
}

.menuButton{
  position:relative;
  margin-right: 5%;
  margin-left: 5%;
  display: inline-block;
  padding: 4px 24px;
  border-radius: 25px;
  border: none;
  background-color: black;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.menuButton:hover {
  background-color: #0055cc;
}

/* MOBILE OPTIMIZATION */

@media (max-width: 600px) {
  img {
    height: 50%;
    width: 70%;
    margin-top: 15%;
  }

  main {
    height: 3500px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items:center;
  }

  header{
  width: 800px;
  background-color: #333;
  color: #fff;
  padding-bottom: 0.5rem;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  }

  section {
    position:relative;
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  .app {
    width: 800px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .header-logo{
    height: 24px;
    width: 24px;
    padding-right: 0px;
    margin-right: 15px;
  }

  .frameContainer{
    margin-left: 250px;
    width: 300px;
  }

  .menuButton:first-child{
    margin-left: 70px;
  }

  .about-text{
    margin-left: 80px;
    width: 400px;
    font-size: 2px;
  }

  .paragraph{
    font-size: 20px;
  }

  .spaceCat{
    width: 30%;
    height: 25%;
    margin-top: 0px;
    margin-left: 280px;
  }

  .tacoCat{
    margin-left: 15%
  }

  .tweet{
    width: 100%;
    height: 25%;
    margin-top: 10%;
    margin-right: 50px;
  }

  .description{
    font-size: 12px;
  }

  .gridContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); 
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }

  .grid1 > img{
    width: 32px;
    height: 32px;
  }
  .grid2 > img{
    width: 32px;
    height: 32px;
  }
  .grid3 > img{
    width: 32px;
    height: 32px;
  }
  .grid4 > img{
    width: 32px;
    height: 32px;
  }
}

